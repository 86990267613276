import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rotate',
  templateUrl: './rotate.component.html',
  styleUrls: ['./rotate.component.scss']
})
export class RotateComponent implements OnInit {

  constructor() { }

  adjustHeight() {
    const h: number = $(window).height();
    $('#divRuota').css('height', h);
  }

  ngOnInit() {
    this.adjustHeight();
  }
}
