import { Component, OnInit } from '@angular/core';
import { WebserverService } from 'src/app/_service/webserver.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  constructor(
    private webServerService: WebserverService,
    private router: Router
  ) { }

  openModalLanguage(event)  {
    $('#modalLingua').modal('show');
    setTimeout(() => {
      $('.modal-backdrop').remove();
    }, 200);
  }

  changeLanguage(lingua, event) {
    if ((lingua !== this.webServerService.globalLingua) && (lingua !== null)) {
      const actualUrl = this.router.url;
      const newUrl = actualUrl.replace(this.webServerService.globalLingua, lingua);

      this.webServerService.globalLingua = lingua;
      this.webServerService.updateLanguage();

      this.router.navigateByUrl(newUrl);

      this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
        this.router.navigate([newUrl]);
      });
    } else {
      $('#modalLingua').modal('hide');
    }
  }

  ngOnInit() {

  }

}
