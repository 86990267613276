import { Injectable, Inject } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable} from 'rxjs';
import { Contenuto } from '../_class/contenuto';
import { Poi } from '../_class/poi';
import {LOCAL_STORAGE, WebStorageService} from 'angular-webstorage-service';
import { ActivatedRoute } from '@angular/router';



export const baseUrl = 'https://webserver.dreamfactorydesign.com/?a=8m%2BSMiHbwsd3bxhFueEgcA%3D%3D';
// export const baseUrl = 'https://webserver.dreamfactorydesign.com/?a=wimXkEAdNU7v7SvCXTeKdg%3D%3D';

@Injectable({
  providedIn: 'root'
})
export class WebserverService {
  contenuto: Contenuto;
  traduzioni: any;
  globalLingua = '';
  globalPoi = 0;

  poiMap = {
    1: 1,
    2: 3,
    3: 3,
    4: 3,
    5: 4,
    6: 4,
    7: 5,
    8: 5,
    9: 9,
    10: 8,
    11: 9,
    12: 3,
    13: 11,
    14: 11
  };

  constructor(
    private http: HttpClient,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private route: ActivatedRoute
    ) { }

  getTraduzioni(): Observable<any> {
    const url = `${baseUrl}&action=getTraduzioni&lang=` + this.globalLingua;
    return this.http.get<any>(url);
  }

  getContenuto(id: number): Observable<Contenuto> {
    const url = `${baseUrl}&action=getContenuto&id=` + id + `&lang=` + this.globalLingua;
    return this.http.get<Contenuto>(url);
  }

  getPoi(id: number): Observable<Poi[]> {
    if (!(id in this.poiMap)) {
      alert('richiesta non valida');
      return;
    }
    this.globalPoi = id;
    const url = `${baseUrl}&action=getPoi&id=` + id + `&lang=` + this.globalLingua;
    return this.http.get<Poi[]>(url);
  }

  updateLanguage(): void {
    this.getTraduzioni()
    .subscribe(data => this.traduzioni = data);
  }

  changeLang(actualUrl: string): void {
    let lang = null;
    if ((actualUrl !== undefined)) {
      const arrayItems = actualUrl.split('/');
      if (arrayItems[1] && (arrayItems[1] !== 'def')) {
        lang = arrayItems[1];
      }
    }
    if ((lang !== this.globalLingua) && (lang !== null)) {
      this.globalLingua = lang;
      this.storage.set('lang', this.globalLingua);
      this.updateLanguage();
    }
  }

  getLang(): void {
    this.globalLingua = this.storage.get('lang');
    // tslint:disable-next-line: max-line-length
    if ((this.globalLingua === null) ||
      (this.globalLingua === 'undefined') ||
      (this.globalLingua === undefined) ||
      (this.globalLingua === '') ||
      ((this.globalLingua !== 'ita') && (this.globalLingua !== 'eng'))
      ) {
      this.globalLingua = 'ita';
      this.storage.set('lang', this.globalLingua);
    }
    this.updateLanguage();
  }

}
