import { DomSanitizer } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { WebserverService } from 'src/app/_service/webserver.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-wellcome',
  templateUrl: './wellcome.component.html',
  styleUrls: ['./wellcome.component.scss']
})
export class WellcomeComponent implements OnInit {
  myLang = '';
  private sanitizer: DomSanitizer

  constructor(
    private webServerService: WebserverService,
    private router: Router,
  ) {
  }

  sanitizeString(string: string) {
    return this.sanitizer.bypassSecurityTrustHtml(string);
  }

  ngOnInit() {
  }

}
