import { Component, OnInit, OnDestroy } from '@angular/core';
import { BarcodeFormat } from '@zxing/library';
import { Router } from '@angular/router';

@Component({
  selector: 'app-scan',
  templateUrl: './scan.component.html',
  styleUrls: ['./scan.component.scss']
})
export class ScanComponent implements OnInit, OnDestroy {

  allowedFormats = [ BarcodeFormat.QR_CODE];
  scanner: any;
  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;
  currentDeviceId: string;

  constructor(private router: Router) { }

  scanSuccessHandler(event) {
    // tslint:disable-next-line: max-line-length
    if ((event.indexOf('https://www.museodelvetrodipiegaro.it/ita/poi/') < 0) && (event.indexOf('https://www.museodelvetrodipiegaro.it/eng/poi/') < 0)) {
      alert('qrcode non valido');
      return;
    }
    event = event.replace('https://www.museodelvetrodipiegaro.it', '');
    event = event.replace('https://test.museodelvetrodipiegaro.it', '');

    this.router.navigateByUrl(event);
  }

  camerasFoundHandler(devices: MediaDeviceInfo[]): void {
  this.availableDevices = devices;
  this.currentDeviceId = devices[devices.length - 1].deviceId;
}
deviceChange() {
  const device = this.availableDevices.find(x => x.deviceId !== this.currentDeviceId);
  this.currentDevice = device;
  this.currentDeviceId = device.deviceId;
}


ngOnInit() {
  }

  ngOnDestroy() {
    this.currentDevice = null;
  }

}
