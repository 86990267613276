import { Component, OnInit } from '@angular/core';
import { WebserverService } from 'src/app/_service/webserver.service';
import { ActivatedRoute } from '@angular/router';



@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  imagesrc = '';
  showedDiv = 0;
  mappa = 0;

  constructor(
    private webServerService: WebserverService,
    private route: ActivatedRoute
  ) {}

  checkSize($event) {
    const w: number = $('#imgItem').innerWidth();
    const h: number = $('#imgItem').innerHeight();

    if ((h === 0) || (w === 0)) {
      setTimeout(() => {
        this.checkSize('');
      }, 100);
    } else {
      $('#imgContainer').width(w).height(h).css('display', 'block');
    }
  }

  showMap(value: any) {
    this.mappa = 0;

    if (!value) {
      if (!this.webServerService.globalPoi) {
        value = 1;
      } else {
        value = this.webServerService.poiMap[this.webServerService.globalPoi];
      }
    }
    this.webServerService.globalPoi = 0;

    this.imagesrc = 'assets/maps/' + value + '.jpg';
    setTimeout(() => {
      this.mappa = value;
    }, 100);

  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      let mapId = +params.mapId;

      if (!mapId) {
        mapId = 0;
      }

      this.checkSize('');
      this.showMap(mapId);
    });
  }
}
