import { Component, OnInit, Input } from '@angular/core';
import { Poi } from 'src/app/_class/poi';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { WebserverService } from 'src/app/_service/webserver.service';


@Component({
  selector: 'app-poi',
  templateUrl: './poi.component.html',
  styleUrls: ['./poi.component.scss']
})
export class PoiComponent implements OnInit {
  elencoPoi: Poi[];
  showedDiv = 0;

  constructor(
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private webServerService: WebserverService
  ) { }

  sanitize(text: string) {
    return this.sanitizer.bypassSecurityTrustHtml(text);
  }

  setOverflow(tipologia: string) {
    if (tipologia === '1') {
      $('.dettaglio-body').css('overflow', 'auto');
    } else {
      $('.dettaglio-body').css('overflow', 'hidden');
    }
  }

  changeSchedaPoi(movimento: string) {
    if ((movimento !== '') && $('#numDiv').length) {
      const maxDiv = $('#numDiv').val();
      switch (movimento) {
        case '+':
          if (this.showedDiv < maxDiv) {
            $('.allPoiItems').css('display', 'none');
            this.showedDiv++;
            $('#poiItem_' + this.showedDiv).css('display', 'block');
            const title: any = $('#poiTitleHidden_' + this.showedDiv).val();
            $('#dettaglioTitoloPoi').html(title);
            const tipologia: any = $('#poiTipologiaHidden_' + this.showedDiv).val();
            this.setOverflow(tipologia);
          }
          $('.imgIconHeader_left').css('display', 'block');
          if (this.showedDiv >= maxDiv) {
            $('.imgIconHeader_right').css('display', 'none');
          }
          break;

        case '-':
          if (this.showedDiv > 1) {
            $('.allPoiItems').css('display', 'none');
            this.showedDiv--;
            $('#poiItem_' + this.showedDiv).css('display', 'block');
            const title: any = $('#poiTitleHidden_' + this.showedDiv).val();
            $('#dettaglioTitoloPoi').html(title);
            const tipologia: any = $('#poiTipologiaHidden_' + this.showedDiv).val();
            this.setOverflow(tipologia);
          }
          $('.imgIconHeader_right').css('display', 'block');
          if (this.showedDiv <= 1) {
            $('.imgIconHeader_left').css('display', 'none');
          }
          break;
      }
    }
  }

  onSwipe(event) {
    const movimento = Math.abs(event.deltaX) > 40 ? (event.deltaX > 0 ? '-' : '+') : '';

    this.changeSchedaPoi(movimento);
  }

  ngOnInit() {
/*
    const audioPlayer = document.getElementById('jingle') as HTMLVideoElement;
    audioPlayer.play();
*/
    const poiId: number = +this.route.snapshot.paramMap.get('poiId');

    this.webServerService.getPoi(poiId)
    .subscribe(poi => {
      this.elencoPoi = poi;

      if (this.elencoPoi.length > 0) {
        const h = $('.bodyContainer').innerHeight() - 80;
        const w = $('.bodyContainer').innerWidth();

        $('.dettaglio-body').innerHeight(h);
        $('.dettaglio-body').innerWidth(w);

        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < this.elencoPoi.length; i++) {
          switch (this.elencoPoi[i].tipologia) {
            case '1':
              let corpo = '<div style="padding: 15px">';
              if (this.elencoPoi[i].audio) {
                // tslint:disable-next-line: max-line-length
                corpo += '<div style="width: 100%; text-align: center;"><audio id="audio' + i + '" controls style="width: 500px;" ><source src="' + this.elencoPoi[i].audio + '" type="audio/mpeg">Your browser does not support the audio element.</audio></div><div style="width: 100%; text-align: center; margin-bottom: 30px"><i>Ascolta questa audio guida</i> </div>';
              }
              corpo += '<div class="elementoPoiTesto">' + this.elencoPoi[i].corpo + '</div>';
              corpo += '</div>';

              this.elencoPoi[i].corpo = corpo;
              break;

            case '2':
              // tslint:disable-next-line: max-line-length
              const style = 'max-width:' + w + 'px;max-height:' + h + 'px;position:absolute;margin:auto;top:80px;left:0;right:0;bottom: 0;';
              this.elencoPoi[i].corpo = '<img src="' + this.elencoPoi[i].corpo + '" style="' + style + '" >';
              break;

            case '3':
              // tslint:disable-next-line: max-line-length
              const style2 = 'width:' + w + 'px;height:' + h + 'px;position:absolute;margin:auto;top:80px;left:0;right:0;bottom: 0;';
              // tslint:disable-next-line: max-line-length
              this.elencoPoi[i].corpo = '<div style="' + style2 + '"><iframe src="https://www.youtube.com/embed/' + this.elencoPoi[i].corpo + '?rel=0" width="100%" height="100%" allowfullscreen></iframe></div>';
              break;
          }
        }

        if (this.elencoPoi.length > 1) {
          $('.imgIconHeader_right').css('display', 'block');
        }

        const primoPoi: Poi = this.elencoPoi[0];
        // tslint:disable-next-line: max-line-length
        $('#titoloDettaglio').html('<span style="text-transform: uppercase;">' + primoPoi.titoloPoi + '</span> | <small id="dettaglioTitoloPoi">' + primoPoi.titolo + '</small>');
        $('#poiLoader').css('display', 'none');

        this.showedDiv = 1;

        setTimeout(() => {
          $('#allPoiContainer').css('display', 'block');
        }, 200);

        this.setOverflow(primoPoi.tipologia);
      }
    });
  }
}
