import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StorageServiceModule} from 'angular-webstorage-service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import * as $ from 'jquery';
import * as bootstrap from 'bootstrap';
import { WellcomeComponent } from './_components/wellcome/wellcome.component';
import { RotateComponent } from './_components/rotate/rotate.component';
import { MapComponent } from './_components/map/map.component';
import { SidebarComponent } from './_components/sidebar/sidebar.component';
import { HomeComponent } from './_components/home/home.component';
import { ScanComponent } from './_components/scan/scan.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { HttpClientModule } from '@angular/common/http';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { PoiComponent } from './_components/poi/poi.component';
import { AiutoComponent } from './_components/aiuto/aiuto.component';
import { InfoComponent } from './_components/info/info.component';

@NgModule({
  declarations: [
    AppComponent,
    WellcomeComponent,
    RotateComponent,
    MapComponent,
    SidebarComponent,
    HomeComponent,
    ScanComponent,
    PoiComponent,
    AiutoComponent,
    InfoComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ZXingScannerModule,
    StorageServiceModule,
    BackButtonDisableModule.forRoot({
      preserveScrollPosition: true
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
