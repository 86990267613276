import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { WebserverService } from './_service/webserver.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'museodelvetropiegaro';
  path: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private webServerService: WebserverService
    ) {
    if (this.router !== undefined) {
      this.router.events.subscribe(val => {
        const actualPath = this.location.path();

        const arrayItems = actualPath.split('/');
        if (arrayItems[2]) {
          this.path = arrayItems[2];
        } else {
          this.path = '';
        }

        this.onResize(true);
      });
    }
  }

  onResize(firstTime: boolean) {

    const h: number = $(window).innerHeight();
    const w: number = $(window).innerWidth();
    $('.fullHeightDiv').css('height', h);

    if (this.path !== undefined) {
      if ((this.path !== '') && (this.path !== 'wellcome') && (this.path !== 'help')) {
        if (h > w) {
          $('.modal').modal('hide');
          $('#divRuota').css('display', 'block');
          $('#mainContent').css('display', 'none');
        } else {
          $('#divRuota').css('display', 'none');
          $('#mainContent').css('display', 'block');

          if (!firstTime) {
            const actualUrl = this.router.url;
            this.router.navigateByUrl(actualUrl);

            this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
              this.router.navigate([actualUrl]);
            });
          }
        }
      } else {
        $('#mainContent').css('display', 'block');
      }
    }
  }

  checkPwa() {
    const serviceWorkerSupported = 'serviceWorker' in navigator;
    if (!serviceWorkerSupported) {
      this.router.navigateByUrl('/home');
    }
  }

  eventOnResize($event) {
    this.onResize(false);
  }

  ngOnInit() {
    this.onResize(true);
    this.checkPwa();

    this.webServerService.getLang();

    // tslint:disable-next-line: deprecation
    this.router.events.subscribe((url: any) => (
      this.webServerService.changeLang(url.url)
     ));

  }
}
