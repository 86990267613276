import { InfoComponent } from './_components/info/info.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WellcomeComponent } from './_components/wellcome/wellcome.component';
import { MapComponent } from './_components/map/map.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './_components/home/home.component';
import { ScanComponent } from './_components/scan/scan.component';
import { PoiComponent } from './_components/poi/poi.component';
import { AiutoComponent } from './_components/aiuto/aiuto.component';


const routes: Routes = [
  {
    path: '',
    component: WellcomeComponent
  },
  {
    path: ':lang/wellcome',
    component: WellcomeComponent
  },
  {
    path: ':lang/help',
    component: AiutoComponent
  },
  {
    path: ':lang/poi/:poiId',
    component: PoiComponent
  },
  {
    path: ':lang/map',
    component: MapComponent
  },
  {
    path: ':lang/map/:mapId',
    component: MapComponent
  },
  {
    path: ':lang/scan',
    component: ScanComponent
  },
  {
    path: ':lang/home',
    component: HomeComponent
  },
  {
    path: ':lang/info',
    component: InfoComponent
  },
  { path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes), BrowserAnimationsModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
