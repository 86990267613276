import { DomSanitizer } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { Contenuto } from '../../_class/contenuto';
import { WebserverService } from 'src/app/_service/webserver.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-aiuto',
  templateUrl: './aiuto.component.html',
  styleUrls: ['./aiuto.component.scss']
})
export class AiutoComponent implements OnInit {
  contenuto: Contenuto;

  constructor(
    private route: ActivatedRoute,
    private webServerService: WebserverService,
    private sanitizer: DomSanitizer
    ) { }

  updateData(contenuto: Contenuto): void {
    this.contenuto = contenuto;
  }

  sanitizeString(string: string) {
    return this.sanitizer.bypassSecurityTrustHtml(string);
  }

  ngOnInit() {
    this.webServerService.getContenuto(3)
    .subscribe(contenuto => this.updateData(contenuto));
  }

}
