import { Component, OnInit } from '@angular/core';
import { Contenuto } from '../../_class/contenuto';
import { WebserverService } from 'src/app/_service/webserver.service';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  contenuto: Contenuto;

  constructor(
    private route: ActivatedRoute,
    private webServerService: WebserverService,
    private sanitizer: DomSanitizer
  ) { }

  updateData(): void {
    this.webServerService.getContenuto(2)
    .subscribe(contenuto => this.contenuto = contenuto);
  }

  sanitizeString(string: string) {
    return this.sanitizer.bypassSecurityTrustHtml(string);
  }

  ngOnInit() {
    this.updateData();
  }
}
